//  libraries
import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { Modal } from '@redq/reuse-modal';

//  containers
import Navbar from 'containers/Navbar';
import DomainSection from 'containers/Banner';
import FeatureSection from 'containers/FeatureSection';
import ControllSection from 'containers/Control';
import TestimonialSection from 'containers/Testimonial';
import PartnerHistory from 'containers/PartnerHistory';
import PaymentSection from 'containers/PaymentSection';
import Footer from 'containers/Footer';
import FeatureSlider from 'containers/FeatureSlider';
import FeatureSliderTwo from 'containers/FeatureSliderTwo';

//  context
import { DrawerProvider } from 'contexts/DrawerContext';

//  components
import SEO from 'components/seo';

//  styles
import { appTheme } from 'styles/theme';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from 'styles/GlobalStyles';
import { ResetCSS } from 'assets/css/style';
import '@redq/reuse-modal/es/index.css';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO title="App | A react next landing page" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <DomainSection />
          <FeatureSection />
          <ControllSection />
          <ConditionWrapper id="keyfeature">
            {innerWidth > 1100 ? <FeatureSlider /> : <FeatureSliderTwo />}
          </ConditionWrapper>
          <PartnerHistory />
          <PaymentSection />
          <TestimonialSection />
          <Footer />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
